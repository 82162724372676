import React from "react";
import GatsbyLink from "gatsby-link";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Markdown } from "../../text/markdown/markdown";
import { EmptyState } from "./empty-state";

export const Meta: DocPageMeta = {
	category: "Visuals",
	title: "Empty State",
	notepad: "",
};
export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Empty State"
			subTitle="Provide context and guidance to users when there is no content to display within a container."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={EmptyState} />
				<Header.H2>Purpose</Header.H2>
				<Paragraph>
					The <Code>EmptyState</Code> informs and guides users when no content is available within a
					container. It aims to:
				</Paragraph>
				<Ul
					items={[
						"Prevent user confusion",
						"Communicate the reason for the absence of content",
						"Provide actionable next steps or suggestions",
						"Maintain a positive user experience",
					]}
				/>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					An <Code>EmptyState</Code> typically includes the following elements:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Illustration</b>: A visual cue that helps convey the state's meaning (success,
							reassurance, initiation, promotion, etc.).
						</>,
						<>
							<b>Heading</b> (Optional): A brief, clear title summarizing the state (e.g., "No
							issues," "No search results").{" "}
						</>,
						<>
							<b>Description</b>: Additional context or instructions to guide the user (e.g., "You
							have not resolved any issues yet." or "Create key metrics to track how your visitors
							complete key activities on your site.").
						</>,
						<>
							<b>Call to Action</b>(Optional): A button or link prompting the user to take a
							specific action (e.g., "View all issues," "Create key metric").
						</>,
					]}
				/>
				<Header.H3>Default</Header.H3>

				<Paragraph>
					This component is used to show an empty state inside of other components. The component
					has the required props <Code>type</Code> and <Code>heading</Code> and the optional prop{" "}
					<Code>description</Code>. The different kinds of empty state <Code>type</Code>s and the
					related illustrations are:
				</Paragraph>
				<Ul
					items={[
						<span key="0">
							Success: Indicates a successful completion of a task. (
							<Code>IllustrationSuccess</Code>)
						</span>,
						<span key="1">
							Reassure: Provides comfort and reassurance during a wait or lack of content. (
							<Code>IllustrationReassure</Code>)
						</span>,
						<span key="2">
							Initiate: Prompts the user to begin an action or process. (
							<Code>IllustrationInitiate</Code>)
						</span>,
						<span key="3">
							Promote: Encourages the user to explore specific features or content. (
							<Code>IllustrationPromote</Code>)
						</span>,
					]}
				/>
				<Paragraph>
					All the illustrations used by this component can be seen on the{" "}
					<GatsbyLink to="/lab/components/Visuals/Illustrations Overview">
						Illustrations Overview
					</GatsbyLink>{" "}
					page.
				</Paragraph>
				<Example fn={BasicUsage} />

				<Header.H2>With markdown</Header.H2>
				<Paragraph>
					You can use <Code>Markdown</Code> formatting for the <Code>description</Code> prop by
					using the <GatsbyLink to="/lab/components/Text/Markdown">Markdown</GatsbyLink> component.
				</Paragraph>
				<Example fn={UsageWithMarkdown} />
				<Header.H2>With link</Header.H2>
				<Paragraph>
					You can add an optional link to the component or an optional button. Not both.
				</Paragraph>
				<Example fn={UsageWithLink} />
				<Header.H2>With button</Header.H2>
				<Paragraph>
					You can add an optional button to the component or an optional link. Not both.
				</Paragraph>
				<Example fn={UsageWithButton} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={EmptyState}
					initialProps={{
						type: "success",
						heading: "Data insights that empower your team",
						description:
							"Siteimprove Analytics gives you powerful insights into visitor behavior and website performance, so you can make data-driven decisions across teams.",
					}}
					exampleValues={{
						type: {
							success: "success",
							reassure: "reassure",
							initiate: "initiate",
							promote: "promote",
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>EmptyState</Code> when
					</Paragraph>
					<Ul
						items={[
							"a search query returns no matches.",
							"a user has not yet created content.",
							"a card, table, or similar container is empty.",
							"data fails to load or display due to an error.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						Position the <Code>EmptyState</Code> within the content area where data would normally
						appear.
					</Paragraph>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>EmptyState</Code> to existing components for visual
								consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								For situations where data is loading, use a{" "}
								<GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink> or{" "}
								<GatsbyLink to="/lab/components/Tables%20and%20lists/Table#loading-state">
									Table with loading state
								</GatsbyLink>{" "}
								instead.
							</>,
							<>
								When a clear error message is available, display it prominently instead of a generic
								empty state. For example, you can use{" "}
								<GatsbyLink to="/lab/components/Feedback/Message">Message</GatsbyLink> component
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between text and background, and use clear, descriptive headings.",
							,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Communicate the reason for the empty state directly and succinctly. ",
							"Avoid technical jargon and lengthy explanations.",
							"Provide helpful suggestions or actions to move the user forward.",
							<>
								Ensure the language and visuals align with your brand's personality and style.
								Follow{" "}
								<a href="https://fancy.siteimprove.com/Writing/tone-of-voice">Tone of voice</a>
							</>,
							<>
								Even in error states, maintain a positive and encouraging tone. Follow{" "}
								<a href="https://fancy.siteimprove.com/Writing/grammar-and-mechanics#active-vs.-passive-voice">
									Active vs. passive voice{" "}
								</a>
								and{" "}
								<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#errors-and-warnings">
									Errors and warnings
								</a>
								.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<EmptyState
		type="success"
		heading="No issues"
		description="You've resolved all issues on this site - well done!"
	/>
);

const UsageWithMarkdown = () => {
	const md = `This is a markdown string, it contains
- A short list
- with an *emphasized* word
- and a [link](https://www.siteimprove.com)`;

	return <EmptyState type="success" heading="No issues" description={<Markdown>{md}</Markdown>} />;
};

const UsageWithLink = () => (
	<EmptyState
		type="reassure"
		heading="No resolved issues"
		description="You have not resolved any issues yet."
		link={{ href: "https://siteimprove.com", text: "See all issues" }}
	/>
);

const UsageWithButton = () => (
	<EmptyState
		type="initiate"
		heading="No key metrics"
		description="Create key metrics to track how your visitors complete key activities on your site."
		button={{ text: "Create key metric", onClick: () => console.log("clicked") }}
	/>
);
