import * as React from "react";
import {
	Button,
	Content,
	ContentProps,
	DataObserveKey,
	FocusableComponent,
	H3,
	Link,
	Paragraph,
	TextContainer,
	VisualComponent,
} from "@siteimprove/fancylib";
import {
	IllustrationInitiate,
	IllustrationPromote,
	IllustrationReassure,
	IllustrationSuccess,
} from "@siteimprove/fancyvisuals";
import { Illustration } from "../illustration/illustration";
import { MarkdownProps } from "../../text/markdown/markdown";

export type EmptyStateProps = {
	/** The type of empty state */
	type: "success" | "reassure" | "initiate" | "promote";
	/** Heading for the empty state */
	heading: string;
	/** Description for the empty state */
	description?: string | React.ReactElement<MarkdownProps>;
} & (
	| { /** Props for optional link */ link?: LinkProps; button?: never }
	| { /** Props for optional button */ button?: ButtonProps; link?: never }
) &
	DataObserveKey &
	VisualComponent &
	FocusableComponent;

type LinkProps = {
	/** Url of the page the link redirects to */
	href: string;
	/** Label for the link */
	text: string;
};

type ButtonProps = {
	/** Text to be displayed inside the button. */
	text: string;
	/** Callback for onClick events */
	onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
	/** URL to which the link will redirect (Should only be used for links that look like buttons) */
	href?: string;
};

const typeMap: Record<EmptyStateProps["type"], string> = {
	success: IllustrationSuccess,
	reassure: IllustrationReassure,
	initiate: IllustrationInitiate,
	promote: IllustrationPromote,
};

export function EmptyState(props: EmptyStateProps): JSX.Element {
	const { link, button, description, heading, type, ...rest } = props;

	const linkAndButtonStyles: Partial<ContentProps> = {
		flexDirection: "column",
		alignItems: "flex-start",
		gap: "large",
	};

	return (
		<Content.Layout
			alignItems="center"
			data-component="empty-state"
			data-observe-key={props["data-observe-key"]}
			padding="large"
			{...rest}
		>
			<Content justifyContent="center">
				<Illustration src={typeMap[type]} size="large" decorative />
			</Content>
			<Content {...(link || button ? linkAndButtonStyles : undefined)}>
				<TextContainer>
					<H3>{heading}</H3>
					{typeof description === "string" ? <Paragraph>{description}</Paragraph> : description}
				</TextContainer>
				{button && (
					<Button href={button.href} onClick={button.onClick} variant="primary">
						{button.text}
					</Button>
				)}
				{link && <Link href={link.href}>{link.text}</Link>}
			</Content>
		</Content.Layout>
	);
}
